import { retry } from '@color/lib';

export async function polyfillUnorm() {
  if (!String.prototype.normalize) {
    // import `unorm` to add `String.prototype.normalize` for IE11. Delete when we kill of IE11.
    // This is about 133 KiB
    // @ts-expect-error: implicitly has an 'any' type
    await retry(() => import('unorm'));
  }
}
