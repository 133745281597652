import { retry } from '@color/lib';
import { shouldPolyfill as shouldPolyfillDisplayNames } from '@formatjs/intl-displaynames/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill';

export async function polyfillIntl() {
  // This polyfill is needed for Safari 9 (or 10) support.
  // These are fairly large packages. Import only if missing.
  // TODO: Some of these might be unnecessary - delete after we test.
  // https://getcolor.atlassian.net/browse/UXTASK-1165
  if (!window.Intl) {
    // These are about 70 KiB
    // @ts-expect-error: Could not find a declaration file for module
    await retry(() => import('intl'));
    // @ts-expect-error: Could not find a declaration file for module
    await retry(() => import('intl/locale-data/jsonp/en.js'));
  }

  if (shouldPolyfillLocale()) {
    await retry(() => import('@formatjs/intl-locale/polyfill'));
  }

  if (shouldPolyfillPluralRules('en')) {
    // These are about 7 KiB
    await retry(() => import('@formatjs/intl-pluralrules/polyfill'));
    await retry(() => import('@formatjs/intl-pluralrules/locale-data/en'));
  }

  if (shouldPolyfillNumberFormat('en')) {
    await retry(() => import('@formatjs/intl-numberformat/polyfill'));
    await retry(() => import('@formatjs/intl-numberformat/locale-data/en'));
  }

  if (shouldPolyfillRelativeTimeFormat('en')) {
    // These are about 21 KiB
    await retry(() => import('@formatjs/intl-relativetimeformat/polyfill'));
    await retry(() => import('@formatjs/intl-relativetimeformat/locale-data/en'));
  }

  if (shouldPolyfillDisplayNames('en')) {
    /**
     * This polyfill is needed for Safari < 14.1. 14.1 was released in 2021.
     */
    await retry(() => import('@formatjs/intl-displaynames/polyfill-force'));
    await retry(() => import('@formatjs/intl-displaynames/locale-data/en'));
  }
}
