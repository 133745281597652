import {
  Environment,
  FeatureFlagProviderHoc,
  getEnvironment,
  getInstrumentedApp,
  retry,
} from '@color/lib';
import { Suspense, lazy } from 'react';

import { getMonitoringConfig } from 'lib/util/monitoring/config';

import { RootLevelErrorBoundary } from './ErrorBoundaries/RootLevelErrorBoundary';

const App = lazy(() => retry(() => import('./App')));
const environment: Environment = getEnvironment();

export const BaseApp = async () => {
  const FeatureFlagProvider = await FeatureFlagProviderHoc();
  return getInstrumentedApp(() => {
    return (
      <RootLevelErrorBoundary>
        <Suspense fallback={<div />}>
          <FeatureFlagProvider>
            <App />
          </FeatureFlagProvider>
        </Suspense>
      </RootLevelErrorBoundary>
    );
  }, getMonitoringConfig(environment));
};
