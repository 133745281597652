const {
  colorConstants = {
    wwwBaseHostUrl: '',
    // We may want to hard code the default stripe token in prod.
    // See https://getcolor.atlassian.net/browse/CCPAGDPR-85
    // and https://getcolor.atlassian.net/browse/SUPPORT-13888
    // FIXME - we should move `stripeToken` to the build process, or alternatively, retrieved via API.
    stripeToken: '',
    googleEmbeddedMapsApiKey: '',
    enable_adwords_conversion_tracking: false,
    apiColorVersion: '',
  },
} = window;

export const {
  wwwBaseHostUrl,
  stripeToken,
  googleEmbeddedMapsApiKey,
  enable_adwords_conversion_tracking,
} = colorConstants;

export function isDevelopmentEnvironment() {
  return process.env.NODE_ENV === 'development';
}

export const rsid_key = 'rsid';
export const genotype_key = 'genotype';
export const outcome_key = 'outcome';
