import { Environment, IMonitoringConfig } from '@color/lib';

function getSentryDsn(environment: Environment): string | undefined {
  return environment === Environment.LOCAL ? undefined : process.env.REACT_APP_SENTRY_DSN;
}

export const IGNORED_SENTRY_ERRORS = [
  'LaunchDarklyFlagFetchError', // TODO(FG-55): Launch darkly has an unhandled exception, should get fixed with a 3.1.4 upgrade
  'ChunkLoadError', // Happens on bad network connections and we show a special "please refresh" error page
  'msDiscoverChatAvailable', // [ENG-912]: An error triggered by iOS Edge, not our code
  'NetworkError', // [ENG-913]: Happens a lot but there's not much we can do on our end. Logged by launch darkly, 3rd party libraries
];

export function getMonitoringConfig(environment: Environment): IMonitoringConfig {
  const appReleaseVersion = process.env.REACT_APP_COLOR_VERSION || 'N/A';
  return {
    environment,
    sentryConfig: {
      dsn: getSentryDsn(environment),
      release: appReleaseVersion,
      ignoreErrors: IGNORED_SENTRY_ERRORS,
    },
  };
}
