import { loggerUtils } from '@color/lib';

// (use the same interface as we define on `window.waffle` in `global.d.ts`)
interface Waffle {
  switch_is_active: (name: string) => boolean;
}

if (!window.waffle) {
  if (window.location.hostname !== 'localhost') {
    // Only log exception when out of dev. Don't show in unit tests.
    loggerUtils.logException(
      new Error('Global `window.waffle` does not exist. (OK if http://localhost:8223)')
    );
  }
}

export const waffle: Waffle = window.waffle || {
  // When Python is down, `wafflejs` may not load, causing page issues in dev.
  // By default, just treat all waffles as being set to fail.
  switch_is_active: () => false,
};
