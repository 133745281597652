import { polyfillDateTimeFormatTimezone } from './polyfillDateTimeFormatTimezone';
import { polyfillIntl } from './polyfillIntl';
import { polyfillUnorm } from './polyfillUnorm';

/**
 * Asynchronously load polyfills. By doing this asynchronously, we enable the ability for the bundler to
 * split each polyfill package into separate bundles.
 *
 * This reduces the payload size for browsers that do not need to be polyfilled. Our polyfills are about 3 MiB
 * of code (compressed to about 1.25 MiB)
 * On a 4G network, the bandwidth is about 4 Mbit/s. This means that these polyfills will take about 2.5 seconds to download.
 * The primary benefit of splitting bundles is that browsers will only load polyfills they need. Modern browsers will load none.
 * See https://getcolor.atlassian.net/browse/UITASK-162
 *
 * @returns Promise that resolves after all polyfills have loaded.
 *   (This is pretty instantaneous on a modern browser since polyfills are not loaded.)
 */
export function loadPolyfills(): Promise<any> {
  return Promise.all([
    polyfillUnorm(),
    polyfillIntl().then(() => polyfillDateTimeFormatTimezone()),
  ]);
}
