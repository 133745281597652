import { ErrorBoundary } from '@sentry/react';

import { PrimitiveErrorFallback } from '../ErrorFallbacks/PrimitiveErrorFallback';

interface Props {
  children?: React.ReactNode;
}

export const RootLevelErrorBoundary: React.FC<Props> = (props) => {
  return (
    <ErrorBoundary fallback={(props) => <PrimitiveErrorFallback {...props} />}>
      {props.children}
    </ErrorBoundary>
  );
};
