import { isEnvironmentLocal } from '@color/lib';

import { isDevelopmentEnvironment } from 'lib/constants';
import { waffle } from 'lib/util/waffle';

/**
 * Negative waffle check to maintain the default imports in production.
 * All sass in code is long since deprecated. Convert this to MUI components and style utilities.
 * The UX infra team blocks all new sass in PRs.
 * See https://getcolor.atlassian.net/browse/UXTASK-666
 *
 * SASS is used as part of generating global static className strings.
 * Due to Lazy loading, the build step cannot deterministically import styles
 * in a predictable order.
 * This causes complicated to debug problems in random parts of the code base.
 *
 * See https://getcolor.atlassian.net/browse/UITASK-104
 * See https://getcolor.atlassian.net/browse/UITASK-53
 *
 * It is fairly complicated to refactor the 100's of non-module sass files in
 * one shot, so we have decided to ignore the non-deterministic load-order
 *
 * This has an unfortunate side effects:
 * 1. Load order is unpredictable, which can (and does) affect css order of precedence.
 * Note - Lazy loading global non-namespaced class names is risky to begin with, so
 * this is not a new issue.
 *
 * However, despite the above, it is far better to not have unpredictable
 * build failures cropping up due to unrelated code changes.
 *
 */
export function isLegacyCssLoadingEnabled() {
  if (isDevelopmentEnvironment() || isEnvironmentLocal()) {
    // Force developers to not load this legacy css
    return false;
  }
  // Note that this will load by default in production. Note the negation here.
  // nosemgrep:semgrep-rules.use-launchdarkly-react
  return !waffle.switch_is_active(
    'UXTASK-666-do-not-allow-legacy-css-since-it-causes-painful-bugs-2022-08-29'
  );
}
