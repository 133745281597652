// `initialReactPolyfills` must be imported before React.
import 'lib/polyfills/initialReactPolyfills';
import { isIE } from 'react-device-detect';

import { initializeReactApp } from './appWrapper';

if (isIE) {
  // eslint-disable-next-line no-alert
  window.alert(`
This browser is no longer supported.
Please use Chrome, Firefox, Safari, Opera, or Edge.
  `);
} else {
  initializeReactApp();
}
