import { getLatestSentryEventId } from '@color/lib';

interface Props {
  error: Error;
  // Sentry seems to be providing an entirely different `eventId` than the one that is POSTed.
  // Unfortunately, there doesn't seem to be a reliable value that we can use.
  eventId: string | null;
  resetError: () => void;
}

/**
 * PrimitiveErrorFallback is a generic error fallback that should work "no matter what"
 * In other words, it should have very few dependencies, and be well tested.
 * It is intended to be used at the root level of the app, when all other error boundaries fail.
 */
export const PrimitiveErrorFallback: React.FC<Props> = (props) => {
  const { error, resetError } = props;
  const url = window.location.href;
  const errorId = getLatestSentryEventId();
  // This component uses inline styles to remove dependency on imported sass modules (which may be lazy loaded)
  // It is intended to be very simple.
  return (
    <div
      style={{
        textAlign: 'center',
        padding: 8,
        maxWidth: 800,
        margin: 'auto',
        fontFamily: 'Noto Sans, Helvetica, sans-serif',
      }}
    >
      <h1>Something went wrong.</h1>
      <p>The engineering team at Color have been notified and are looking at the issue.</p>
      {errorId != null && (
        <>
          <h2>
            <span>Tracking reference Id: </span>
            <span style={{ color: 'red' }}>{errorId}</span>
          </h2>
          <p>
            <span>Send an </span>
            <a
              href={`mailto:support@color.com?subject=Tracking reference Id ${errorId}&body=I ran into a problem when opening ${url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              email to support
            </a>
            .
          </p>
        </>
      )}
      <p>URL: {url}</p>
      <button
        type="button"
        style={{ border: '1px solid', padding: 16, cursor: 'pointer' }}
        onClick={resetError}
      >
        Try Again
      </button>
      <hr />

      {window.__ENV__ === 'development' && (
        <>
          <p>**************** Note to DEVS ****************</p>
          <p>ErrorBoundary is only shown for a short time in dev mode.</p>
          <p>{String(error)}</p>
        </>
      )}
    </div>
  );
};
